:root {
    --main_blue: #00aeef;
    --sec_blue: #0b709a;
    --pale_blue: #eaf6fe;
    --pale2_blue: #bce4fa;
    --pale3_blue: #83d0f5;
    --txt_black_1: #130e09;
    --white: #ffffff;
    --header1: #00b1eb;
    --header2: #5bc5f2;
    --purple: #3b4395;
    --purple_pale: #ebeaf6;
}

html {
    scroll-behavior: smooth;
}

/*************/
/*  CONTENT  */
/*************/
.contentLink {
    color: var(--main_blue);
}
.contentLink:hover {
    color: var(--sec_blue);
}

.pages {
    width: 100%;
    min-height: 100%;
    background-color: white;
    padding: 2rem 4rem 5rem 4rem;
}
.article_content {
    text-align: left;
    padding-top: 0.5rem;
    padding-bottom: 2rem;
}
.article_content h3 {
    font-weight: 700;
    padding-bottom: 0.5rem;
}
.article_content p {
    padding-bottom: 1.5rem;
}
.article_content p.halfPad {
    padding-bottom: 0.5rem;
}
.article_content p.noPad {
    padding-bottom: 0rem;
}

.infoBox {
    border: solid 1px var(--txt_black_1);
    padding: 0.65rem;
    margin: 0.5rem 0;
    font-size: 0.75rem;
    line-height: 1rem;
}

.infoBox p {
    padding-bottom: 0.3rem;
}

div {
    white-space: pre-wrap;
}

.text-xxs {
    font-size: 0.5rem;
    line-height: 0.75rem;
}

.infoBox span,
.sup {
    padding: 0 2px;
    vertical-align: super;
}

.col2 {
    column-count: 2;
    column-gap: 3rem;
}
.noColumnBreak {
    break-inside: avoid;
}

@media (max-width: 767px) {
    .col2 {
        column-count: 1;
    }
    .pages {
        padding: 1.5rem 3rem 5rem 3rem;
    }
}
@media (max-width: 511px) {
    .pages {
        padding: 1rem 2rem 5rem 2rem;
    }
}

.footnote {
    color: var(--main_blue);
}

/*************/
/*  BULLETS  */
/*************/
.bullets {
    padding-left: 30px;
    padding-bottom: 2rem;
}
.bullets li {
    list-style-type: disc;
}
.bullets ul {
    padding-left: 20px;
}
.bullets ul li {
    list-style-type: circle;
}

.bullets.pb-0 {
    padding-top: 0.5rem;
    padding-bottom: 0;
}

.alphaList {
    list-style-type: lower-alpha;
}
li p {
    padding-bottom: 0.5rem !important;
}

table .bullets {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
/************/
/*  TABLES  */
/************/

table td,
table th {
    padding: 1px 4px;
    vertical-align: top;
}

.purpleCellFirstTd tr td:first-child {
    color: var(--purple);
    font-weight: 500;
}

.allBorders,
.allBorders th,
.allBorders td {
    border: solid 1px var(--main_blue);
    vertical-align: top;
}
.allBorders tr td:first-child {
    color: var(--main_blue);
}
.allBorders thead th {
    font-weight: 300;
}
.allBorders p,
.allBorders .bullets {
    padding-bottom: 0.5rem;
}

.allBorders.purple,
.allBorders.purple th,
.allBorders.purple td {
    border: solid 1px var(--purple);
}
.allBorders.purple tr td:first-child {
    color: var(--purple);
}

.tableRightBorder tr td:not(:last-child) {
    border-right: 2px solid var(--main_blue);
}
.tableRightBorder.purple tr td:not(:last-child) {
    border-right: 2px solid var(--purple);
}
.tableRightBorder p {
    padding: 0;
}

.tableTextLR th,
.tableTextLR td,
.tableTextLC th,
.tableTextLC td {
    text-align: left;
}
.tableTextLR td + td,
.tableTextLR th + th {
    text-align: right;
}
.tableTextLC td + td,
.tableTextLC th + th {
    text-align: center;
}

.tableStripes tbody tr:nth-child(odd) {
    background-color: var(--white);
}
.tableStripes tbody tr:nth-child(even) {
    background-color: var(--pale_blue);
}
.tableStripes.purple tbody tr:nth-child(even) {
    background-color: var(--purple_pale);
}

.tableBorder {
    border: 2px solid var(--main_blue);
}

.tableBorder.purple {
    border-color: var(--purple);
}

/******************/
/*  TABLE CIRCLE  */
/******************/
.tableCircle {
    position: absolute;
    top: 6px;
    right: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}
.tableCircle.orange {
    background-color: #f36c21;
}
.tableCircle.yellow {
    background-color: #ffc20e;
}

/******************/
/*  TABLE SCROLL  */
/******************/
.table-scroll {
    position: relative;
    width: 100%;
    z-index: 1;
    overflow-x: scroll;
}
.table-scroll table {
    width: 100%;
    min-width: 900px;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
}

/* .table-scroll thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0; } */
/* .table-scroll tfoot,
.table-scroll tfoot th,
.table-scroll tfoot td {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index:4; } */

.table-scroll table th:first-child,
.table-scroll table td:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
}

.table-scroll .tableStripes tbody tr:nth-child(odd) td:first-child {
    background-color: var(--white);
}
.table-scroll .tableStripes tbody tr:nth-child(even) td:first-child {
    background-color: var(--pale_blue);
}
.table-scroll .tableStripes.purple tbody tr:nth-child(even) td:first-child {
    background-color: var(--purple_pale);
}

@media (max-width: 511px) {
    .table-scroll.tableMinXS600 table {
        min-width: 600px;
    }
}

/********************/
/*  TABLE SCROLL 2  */
/********************/
.table-scroll-2 {
    display: block;
    overflow-x: scroll;
}

.dTable {
    table-layout: fixed;
    width: 100%;
    background-color: var(--white);
}

.dTable tr th:first-child {
    background-color: var(--main_blue);
    width: 180px;
}

.dTable.notFixed {
    table-layout: auto;
}

.dTable .topHeader {
    text-align: center !important;
    color: var(--main_blue);
    border: 2px solid var(--main_blue);
}
.dTable .botHeader {
    color: var(--white);
    padding: 2px 10px;
}
.dTable .botHeader:hover {
    color: var(--sec_blue);
}
.dTable .female {
    background-color: var(--header1);
}
.dTable .male,
.dTable .total {
    background-color: var(--header2);
}
.dTable th .gender {
    margin-top: 1px;
    padding-right: 2px;
}
.dTable th .sort {
    font-size: 0.75rem;
    padding-left: 4px;
}
.dTable tfoot {
    font-weight: 500;
    background-color: var(--pale3_blue);
}
.dTable tfoot td:first-child {
    background-color: var(--pale3_blue);
}

@media (max-width: 511px) {
    table {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
}

/***************/
/*  ANIMATION  */
/***************/
.ani03s {
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

/**************/
/*  SORT BOX  */
/**************/
.sortBox {
    display: flex;
    flex-wrap: nowrap;
}
.sortBox .left_box,
.sortBox .right_box {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    border: solid 1px var(--main_blue);
    margin-bottom: 1rem;
}
.sortBox .left_box {
    flex-grow: 1;
    margin-right: 4rem;
}

@media (max-width: 767px) {
    .sortBox .left_box {
        margin-right: 3rem;
    }
}
@media (max-width: 639px) {
    .sortBox .left_box {
        margin-right: 2rem;
    }
}
@media (max-width: 511px) {
    .sortBox {
        flex-direction: column;
    }
    .sortBox .left_box,
    .sortBox .right_box {
        flex-wrap: wrap;
        width: 100%;
    }
}

/****************/
/*  CHECKBOXES  */
/****************/
.round_checkbox_box {
    position: relative;
    width: 1rem;
    height: 1rem;
}
.center input[type="checkbox"] + label,
.center input[type="checkbox"] {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.center input[type="checkbox"] {
    opacity: 0;
    pointer-events: none;
}
.center input[type="checkbox"] + label {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: solid 1px var(--main_blue);
    cursor: pointer;
    box-shadow: 1px 1px 1px 1px rgba(3, 79, 32, 0.5);
    transition: box-shadow 0.4s cubic-bezier(0.17, 0.67, 0.83, 0.67),
        background 0.4s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.center input[type="checkbox"]:checked + label {
    background-color: var(--main_blue);
    box-shadow: 1px 1px 1px 1px rgba(3, 79, 32, 0.5),
        0 0 0 3px var(--white) inset;
}

/*************************/
/*  SORT BTNS / CHECKES  */
/*************************/
.sortBtnBox button {
    color: var(--main_blue);
}

.sortBtnBox button:after,
.sortBtnBox .ascending:after,
.sortBtnBox .descending:after {
    font-family: "e-bulletin";
    font-size: 0.8rem;
    padding-left: 6px;
}
.sortBtnBox button:after {
    content: "\71";
}
.sortBtnBox .ascending:after {
    content: "\6f";
}
.sortBtnBox .descending:after {
    content: "\70";
}
.sortBtnBox button:hover {
    color: var(--sec_blue);
}

/************/
/*  BUTTON  */
/************/
button:focus,
button:hover,
button:active {
    outline: none !important;
}

/***************/
/*  CHART BAR  */
/***************/
.barChartBox {
    position: relative;
    width: 100%;
    min-height: 50px;
    z-index: 1;
}
.barChart {
    padding: 30px 20px 0 0;
}
.bar {
    margin: 0.2rem 0;
    display: block;
}
.chartRow {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    width: 100%;
}
.labelBox {
    width: 12rem;
    padding-right: 8px;
    text-align: right;
    font-size: 0.8rem;
}
.bars {
    flex-grow: 1;
    margin-bottom: 4px;
}
.lines {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
    display: flex;
    flex-flow: row nowrap;
    padding: 20px 20px 0 0;
    text-align: right;
    color: grey;
    font-size: 0.8rem;
}
.lines span {
    position: relative;
    top: -17px;
    right: -5px;
}
.solidLine span {
    right: -12px !important;
}
.solidLine {
    border-right: 1px solid grey;
}
.dotLines {
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
}
.dotLines div {
    background-image: linear-gradient(
        to bottom,
        #333 10%,
        rgba(255, 255, 255, 0) 0%
    );
    background-position: right;
    background-size: 1px 10px;
    background-repeat: repeat-y;
    flex-grow: 1;
}

@media (max-width: 511px) {
    .labelBox {
        width: 9rem;
        font-size: 0.7rem;
    }
}
@media (max-width: 383px) {
    .labelBox {
        width: 6rem;
        font-size: 0.6rem;
    }
}

/*******************/
/*  CHART TOOLTIP  */
/*******************/
.barTooltipe {
    color: black !important;
    background-color: var(--pale_blue) !important;
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.barTooltipe:after {
    border-top-color: var(--pale_blue) !important;
}
.barTooltipe h3 {
    font-size: 1.5rem !important;
    font-weight: bold !important;
    color: var(--main_blue) !important;
}
.barTooltipe h3 {
    font-size: 1.5rem !important;
    font-weight: bold !important;
    color: var(--main_blue) !important;
}
.barTooltipe p {
    padding-bottom: 5px !important;
}
.barTooltipe .tollValue {
    text-align: right !important;
    font-size: 2rem;
}

/*****************/
/*  MAP TOOLTIP  */
/*****************/
.map_z_info {
    z-index: 2;
}
.jvectormap-tip {
    padding: 3px 10px 10px !important;
    background-color: white !important;
}
.jvectormap-tip h1,
.jvectormap-tip h4 {
    color: var(--main_blue);
    font-weight: bold;
    text-align: left;
    margin: 5px;
}
.jvectormap-tip h1 {
    font-size: 1.4rem;
}
.jvectormap-tip h4 {
    font-size: 0.8rem;
}
.jvectormap-tip p {
    font-size: 0.8rem;
    color: var(--txt_black_1);
    display: flex;
    padding: 0 5px;
}
.jvectormap-tip span {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    border-radius: 50%;
}
.jvectormap-tip span.bg-map_progress {
    background-color: #f36c21;
}
.jvectormap-tip span.bg-map_available {
    background-color: #7cfc00;
}
.jvectormap-tip span.bg-map_n_strategy {
    background-color: gray;
}
.jvectormap-tip span.bg-map_n_regulations {
    background-color: black;
}

/* IMG MAP */
.imgMapContainer {
    width: 1000px;
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
}

area {
    cursor: pointer;
}

.imgMapContainer div {
    width: 1000px !important;
}

/* @media (max-width: 1023px) {
  .imgMapContainer,
  .imgMapContainer div {
    width: 700px !important; }
}
@media (max-width: 767px) {
  .imgMapContainer,
  .imgMapContainer div {
    width: 580px !important; }
}
@media (max-width: 639px) {
  .imgMapContainer,
  .imgMapContainer div {
    width: 450px !important; }
}
@media (max-width: 511px) {
  .imgMapContainer,
  .imgMapContainer div {
    width: 350px !important; }
}
@media (max-width: 383px) {
  .imgMapContainer,
  .imgMapContainer div {
    width: 290px !important; }
} */

.notes_box {
    margin: 0.5rem 0;
    padding: 0.25rem 0.5rem;
    border: solid 1px var(--txt_black_1);
    font-size: 0.75rem;
    line-height: 1rem;
}
.notes_box p {
    padding: 0;
}

.modalBtn {
    color: var(--main_blue);
}

.parallax {
    min-height: 300px;
    background-attachment: fixed;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}

.cover {
    min-height: 350px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}

.npPad {
    padding: 0 !important;
}
