@charset "UTF-8";

@font-face {
    font-family: "e-bulletin";
    src: url("../fonts/e-bulletin/e-bulletin.eot");
    src: url("../fonts/e-bulletin/e-bulletin.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/e-bulletin/e-bulletin.woff") format("woff"),
        url("../fonts/e-bulletin/e-bulletin.ttf") format("truetype"),
        url("../fonts/e-bulletin/e-bulletin.svg#e-bulletin") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "e-bulletin" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
}

.icon-down:before {
    content: "\61";
}
.icon-bars:before {
    content: "\62";
}
.icon-facebook-icon:before {
    content: "\63";
}
.icon-web-icon:before {
    content: "\64";
}
.icon-twitter-icon:before {
    content: "\65";
}
.icon-share-icon:before {
    content: "\66";
}
.icon-chart-icon:before {
    content: "\67";
}
.icon-close:before {
    content: "\68";
}
.icon-text-icon:before {
    content: "\69";
}
.icon-facebook:before {
    content: "\6a";
}
.icon-twitter:before {
    content: "\6b";
}
.icon-linkedin-square:before {
    content: "\6c";
}
.icon-male:before {
    content: "\6d";
}
.icon-female:before {
    content: "\6e";
    top: 2px;
}
.icon-sort-asc:before {
    content: "\6f";
}
.icon-sort-desc:before {
    content: "\70";
}
.icon-sort:before {
    content: "\71";
}
.icon-tables-icon:before {
    content: "\72";
}
.icon-map-icon:before {
    content: "\73";
}
.icon-info:before {
    content: "\74";
}
.icon-gotop:before {
    content: "\75";
}
.icon-pdf:before {
    content: "\76";
}
